import { getAppSDK } from '@finn/ua-app';
import { useSession } from '@finn/ui-utils';
import { compareVersions } from 'compare-versions';
import { useEffect } from 'react';

interface IUseAuthProp {
  queryParam: string;
  disabled?: boolean;
}

const sendRedirectMessageToApp = ({
  queryParam,
  type,
}: {
  queryParam: string;
  type: 'login_redirect' | 'email_not_verified_redirect';
}) => {
  const appSDK = getAppSDK();

  if (appSDK) {
    const query = new URLSearchParams(queryParam);

    if (type === 'login_redirect') {
      appSDK.sendMessageToApp({
        type: 'login_redirect',
        nextURL: query.get('next') || '',
      });
    } else if (type === 'email_not_verified_redirect') {
      appSDK.sendMessageToApp({
        type: 'email_not_verified_redirect',
      });
    }
  }
};

export const useAuth = ({ queryParam, disabled }: IUseAuthProp) => {
  const [session, isSessionLoading] = useSession();

  useEffect(() => {
    const version = getAppSDK()?.getTrackingProps().app_version;
    const isWebFirstNavigation = version
      ? compareVersions(version, '1.47.0') >= 0
      : false;
    if (disabled || isWebFirstNavigation) {
      return;
    }

    if (!isSessionLoading && !session) {
      sendRedirectMessageToApp({ queryParam, type: 'login_redirect' });
      // as home screen lives in UA and deployed on separate url,
      // we need to use window for proper redirect
      window.location.href = `${window.location.origin}?${queryParam}`;
    } else if (!isSessionLoading && !session?.user.email_verified) {
      sendRedirectMessageToApp({
        queryParam,
        type: 'email_not_verified_redirect',
      });
      window.location.href = `${window.location.origin}?${queryParam}`;
    }
    // Disabling the following lint rule is usually an indicator of a bug. I
    // don't know this well enough to change the code, though, so let's disable
    // it.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, isSessionLoading, disabled]);

  return { isAuthenticated: !isSessionLoading && Boolean(session) };
};
