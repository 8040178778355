import { useAuth } from '@finn/b2c-cp/hooks/useAuth';
import { Spinner } from '@finn/design-system/atoms/spinner';
import { LoginModal } from '@finn/platform-modules';
import { Footer } from '@finn/ua-footer';
import { Header } from '@finn/ua-header';
import { ModalContainer, ModalKey } from '@finn/ua-modals';
import { BasePageData, FooterData, HeaderData } from '@finn/ui-cosmic';
import { isMobileApp } from '@finn/ui-utils';
import get from 'lodash/get';
import { useRouter } from 'next/router';
import React, { ReactNode } from 'react';

type PageProps = {
  children: ReactNode;
  data: BasePageData;
  checkoutFooter?: boolean;
  shouldUseAuth?: boolean;
};

const DefaultLayout: React.FC<PageProps> = ({
  children,
  data,
  shouldUseAuth = true,
}) => {
  const router = useRouter();

  const { isAuthenticated } = useAuth({
    queryParam: `login=true&next=${router.basePath}${router.asPath}`,
    disabled: !shouldUseAuth,
  });

  const isMainPage = router?.pathname?.includes?.('/main');

  const footerData = get(data, 'metadata.footer', '') as FooterData;
  const hideHeaderFooter = isMobileApp();

  // if user is not authenticated web by default showing loader and redirects to home
  // in case of mobile app we are not showing loader and not redirecting to login page, instead we show no subscription screen
  // so we need to render children in case of mobile app even for non authenticated user
  const shouldRenderChildren = isAuthenticated || hideHeaderFooter;

  if ((!router.isReady && !hideHeaderFooter) || !data?.slug) {
    return <div style={{ minHeight: 1600 }} />;
  }

  return (
    <div className="relative flex min-h-screen flex-col">
      {hideHeaderFooter ? null : (
        <Header data={get(data, 'metadata.header', {}) as HeaderData} />
      )}
      <ModalContainer
        modalKey={ModalKey.LOGIN}
        ModalComponent={LoginModal}
        cartURL=""
      />
      {shouldRenderChildren ? (
        <div className="flex flex-1 flex-col">{children} </div>
      ) : isMainPage ? (
        <div className="container">
          <div className="mt-16">
            <h3>Please log in</h3>
          </div>
        </div>
      ) : (
        <div className="m-auto h-screen w-14">
          <Spinner className="h-full w-14" />
        </div>
      )}
      {hideHeaderFooter ? null : <Footer data={footerData} variant="minimal" />}
    </div>
  );
};

export default DefaultLayout;
