import HeadTags from '@finn/b2c-cp/deprecated/HeadTags';
import ProductSlider from '@finn/b2c-cp/deprecated/modules/ProductSlider';
import Page from '@finn/b2c-cp/layouts/Default';
import { Button } from '@finn/design-system/atoms/button';
import { ErrorPageData } from '@finn/ui-cosmic';
import { getLocaleFromContext, parseToHtml } from '@finn/ui-utils';
import get from 'lodash/get';
import { GetStaticProps } from 'next';
import Link from 'next/link';

type ErrorPageProps = {
  pageData: ErrorPageData;
};

const ErrorPage = ({ pageData }: ErrorPageProps) => {
  const title = parseToHtml(get(pageData, 'metadata.headlines.title', ''));
  const seoHeader = get(pageData, `metadata.seo_header_404`, {});
  const description = parseToHtml(
    get(pageData, `metadata.headlines.description_404`, '')
  );
  const buttonText = parseToHtml(get(pageData, 'metadata.button.text', ''));
  const buttonHref = get(pageData, 'metadata.button.href', '');

  return (
    <Page data={pageData} shouldUseAuth={false}>
      <HeadTags seoHeader={seoHeader} isNoIndexNoFollow={true} />
      <div className="container !mb-16 !mt-16 md:!mb-14">
        <h1 className="mobile-t1-semibold md:web-t1-semibold">{title}</h1>
        <span className="body-16-light ml-1">{description}</span>
      </div>
      <ProductSlider
        title={get(pageData, 'metadata.vehicles_slider_title', '')}
      />
      <div className="container !mt-12">
        <Link href={buttonHref} legacyBehavior>
          <Button href={buttonHref}>{buttonText}</Button>
        </Link>
      </div>
    </Page>
  );
};

export const getStaticProps: GetStaticProps = async (ctx) => {
  const { getCosmicObject } = await import('@finn/ui-cosmic');
  const pageData = await getCosmicObject({
    slug: 'error-page',
    locale: getLocaleFromContext(ctx),
  });

  return {
    props: {
      pageData,
    },
  };
};

export default ErrorPage;
